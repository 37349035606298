import React from "react"
import { Helmet } from "react-helmet"
import {
  already,
  Banner,
  Slider,
  BannerStyle,
  IconTextIntroductionSection,
  PaddingHalfWrapper,
  SectionWrapper,
} from "@igloonet-web/shared-ui"
import {
  ContactForm,
  Layout,
  SliderMarketing,
  SectionTabs,
  ReferencesBigIcon,
} from "../components"
import { HR_MARKETING_USERS_REFERENCES } from "../model/hr-marketing-user-references"
import { WHAT_WE_OFFER } from "../model/hr-marketing-what-we-offer"
import { PERSON_SLIDER } from "../model/person-slider-marketing"
import { REFERENCE_HR_MARKETING } from "../model/reference-hr-marketing"

const HrMarketing = (): React.ReactNode => {
  return (
    <Layout>
      <Helmet>
        <title>HR marketing | igloonet</title>
        <meta
          name="description"
          content={`Získejte uchazeče s využitím digitálního marketingu. V online světě se pohybujeme už ${already(
            2007
          )}. Náborové kampaně, školení hr marketingu i kariérní stránky.`}
        />
      </Helmet>

      <Banner
        style={BannerStyle.HRmarketing}
        orangeHeading="HR marketing "
        blackHeading="od marketérů"
        text={`V online světě se pohybujeme už ${already(
          2007
        )}. Pomáháme firmám i\u00A0školám získat uchazeče s\u00A0využitím moderních technik digitálního marketingu.`}
      />

      <PaddingHalfWrapper>
        <IconTextIntroductionSection data={WHAT_WE_OFFER} />
      </PaddingHalfWrapper>

      <SectionTabs />

      <SectionWrapper>
        <ReferencesBigIcon
          heading="Online marketing"
          data={REFERENCE_HR_MARKETING}
        />
      </SectionWrapper>

      <SliderMarketing data={HR_MARKETING_USERS_REFERENCES} />

      <Slider title={"Poznejte náš tým"} data={PERSON_SLIDER} />

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default HrMarketing
