import Image1 from "../images/marketing/reference_image_1.svg"
import Image3 from "../images/marketing/reference_image_3.svg"
import Image4 from "../images/marketing/reference_image_4.svg"
import Image5 from "../images/marketing/reference_image_5.svg"
import Image6 from "../images/marketing/reference_image_6.svg"
import Image7 from "../images/marketing/car_penguin.svg"
import KiwiLogo from "../images/reference/kiwi/logo.png"
import SALogo from "../images/reference/student-agency/logoSA.png"
import FSILogo from "../images/reference/fsivut/logo.png"
import TMobileLogo from "../images/reference/t-mobile/logo.png"
import IFELogo from "../images/reference/ife/logoife.png"
import BorgersLogo from "../images/reference/borgers/logo.jpg"

export const REFERENCE_HR_MARKETING = [
  {
    src: Image1,
    alt: "",
    company: "Kiwi.com",
    text: "Konzultujeme propojení marketingu a\u00A0dat",
    link: "marketing/reference/kiwi",
    logo: KiwiLogo,
    logoAlt: "",
  },
  {
    src: Image6,
    alt: "",
    company: "Student Agency",
    text: "Proškolili jsme interní tým",
    link: "marketing/reference/student-agency",
    logo: SALogo,
    logoAlt: "",
  },
  {
    src: Image4,
    alt: "",
    company: "FSI VUT",
    text: "Zastavili jsme úbytek přihlášek",
    link: "marketing/reference/fsivut",
    logo: FSILogo,
    logoAlt: "",
  },
  {
    src: Image7,
    alt: "",
    company: "Borgers",
    text: "Výzkum, EVP a náborové kampaně pro Borgers",
    logo: BorgersLogo,
    logoAlt: "",
    link: "marketing/reference/borgers",
  },
  {
    src: Image5,
    alt: "",
    company: "T‑Mobile",
    text: "Pro T‑Mobile jsme připravili odbornou část tendru na online agenturu T‑mobile",
    logo: TMobileLogo,
    logoAlt: "",
  },
  {
    src: Image3,
    alt: "",
    company: "IFE",
    text: "Pomáháme IFE obsadit desítky pozic díky online reklamě",
    link: "marketing/reference/ife",
    logo: IFELogo,
    logoAlt: "",
  },
]
