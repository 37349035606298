import HeartScreen from "../images/hr-marketing/heart_screen.svg"
import PenguinScreen from "../images/hr-marketing/penguin_screen.svg"
import TablePenguin from "../images/hr-marketing/table_penguin.svg"

export const WHAT_WE_OFFER = [
  {
    heading: "Náborové kampaně",
    text: [
      "Oslovíme vaše kandidáty přes výkonnostní reklamu na sociálních sítích a\u00A0ve vyhledávačích. Využijte svůj náborový rozpočet efektivněji.",
    ],
    src: PenguinScreen,
    alt: "",
    href: "#vic-o-kampanich",
  },
  {
    heading: "Školení",
    text: [
      "Náborovou kampaň zvládnete díky nám sami. Naučíme vás práci s\u00A0marketingovými nástroji a\u00A0ukážeme, jaká data můžete v\u00A0náborových kampaních využívat.",
    ],
    src: TablePenguin,
    alt: "",
    href: "#vic-o-skolenich",
  },
  {
    heading: "Kariérní stránky",
    text: [
      "Zaujměte kandidáty moderním kariérním webem. Vytvoříme pro vás stránky založené na analytice a\u00A0výzkumu.",
    ],
    src: HeartScreen,
    alt: "",
    href: "#vic-o-strankach",
  },
]
