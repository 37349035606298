import IFEPerson from "../images/reference/ife/fiser.png"
import ZezulaPerson from "../images/reference/snowboard-zezula/reaction-marketing.png"
import KiwiPerson from "../images/reference/kiwi/pezlarova.jpg"
import LogoVUT from "../images/reference/vutbrno/logo.png"

export const HR_MARKETING_USERS_REFERENCES = [
  {
    personName: "prof. RNDr. Radim Chmelík, Ph.D.",
    img: LogoVUT,
    position: "proděkan FSI VUT",
    id: 1,
    text: "Se spoluprací s firmou igloonet jsme velmi spokojeni. Jejich příprava i realizace reklamních kampaní se vyznačuje bezproblémovou komunikací o cílení, časových požadavcích, náplni i finančních otázkách. Provedení přispělo k nečekaně vyššímu počtu podaných přihlášek do bakalářského studia, a to v situaci, kdy na srovnatelných fakultách v ČR tyto počty klesají.",
  },
  {
    personName: "Vít Fišer",
    img: IFEPerson,
    position: "HR Marketing specialista, IFE",
    id: 2,
    text: "igloonet nám pomohl navýšit objem životopisů řádově o desítky a návštěvnost kariérních stránek v řádech tisíců uživatelů měsíčně, díky čemuž jsme mohli obsadit mnoho volných pozic ve výrobě i kancelářích. Oceňuji zejména flexibilní reakce na naše požadavky, propracovanou strukturu online kampaní, transparentní komunikaci, pravidelný kvalitní reporting a aktivitu nad rámec domluvené spolupráce. V případě další kampaně v online prostředí bychom volili opět igloonet.",
  },
  {
    personName: "Martin Zezula",
    img: ZezulaPerson,
    position: "ředitel",
    id: 3,
    text: "Na igloonetu se mi líbí spojení profesionality s neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z paty.",
  },
  {
    personName: "Lucie Pezlarová",
    img: KiwiPerson,
    position: "Head of Analytics Kiwi.com",
    id: 4,
    text: "S Adamem spolupracujeme již od roku 2018. Pomáhá analytikům i marketingovým manažerům Kiwi.com nastavit vhodné postupy pro vyhodnocování celého portfolia marketingových aktivit tak, aby výsledný reporting dával smysl a hodnoty byly použitelné pro rozhodování. Adam je předním expertem a navíc důvěryhodným a organizovaným partnerem pro diskusi; spolupráce s ním si velmi vážím.",
  },
]
